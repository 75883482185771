import { render, staticRenderFns } from "./jejufisherman.vue?vue&type=template&id=41818732&scoped=true&"
import script from "./jejufisherman.vue?vue&type=script&lang=js&"
export * from "./jejufisherman.vue?vue&type=script&lang=js&"
import style0 from "./jejufisherman.vue?vue&type=style&index=0&id=41818732&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41818732",
  null
  
)

export default component.exports