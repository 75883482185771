<template>
  <div class="top">
    <div class="header">
      <div class="headerimage" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <!--        <div>-->
        <!--          <img src="@/assets/images/pleisure/pleisure_headerimage.jpg" />-->
        <!--        </div>-->
        <div class="col-lg-12 rowSpace"></div>
        <div class="text-center col-lg-12 mainTitle">제주어부</div>
        <div class="mainTitleLine">
          <hr />
        </div>
        <div class="col-lg-12 text-center mainIntro">제주 바다를 고스란히 느끼는 익스트림 액티비티</div>
        <div class="rowSpace"></div>
        <div class="col buttonArea">
          <router-link to="/p/Pleisure" class="prev-button">이전 </router-link>
          <router-link to="/p/Reservpleisure/4" class="resserve-button"> 예약신청 </router-link>
        </div>
        <div class="rowSpace"><hr /></div>
        <GallerySlideCarousels :slides="main_slides" :slide-count="4" :thumb-slide-width="60" />
      </div>
    </div>
    <div class="container subContainer">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 mainInfo">
          <div>지루한 일상에 새로운 활력을 줄 수 있는 대표적인 스포츠 액티비티 입니다.</div>
          <div>
            실제 배를 타고 나가 바다에서 낚시를 체험할 수 있는 상품이며, 독배로 운영되어 프라이빗하게 지인들과의 즐거운
            시간을 보낼 수 있습니다.
          </div>
          <div>제주에서만 만날 수 있는 어종들을 낚시하고, 즉석회까지 즐겨보는 특별한 추억을 만들어 보세요.</div>
          <div>※ 연령제한 14세 이상</div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">공통 의전 및 서비스</div>
          <div class="subDatail">
            <div>- 차량 의전 서비스 (벤츠 스프린터 / 카니발 하이리무진)</div>
            <div>- 핀크스 의전 담당 매니저 케어</div>
            <div>- 어메니티, 미니바 제공</div>
            <div>- F&B 세트 제공</div>
          </div>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">메인 프로그램</div>
          <div class="subDatail">
            <div>1. 뱅에돔낚시 : 하효항 출발</div>
            <div>2. 제철낚시 : 사계항 출발</div>
          </div>
          <div class="rowSpace"></div>
          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>옵션</td>
                <td>최대인원</td>
                <td>소요시간</td>
                <td>요금(2인기준)</td>
                <td>비고</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>뱅에돔 낚시</td>
                <td rowspan="2">10인</td>
                <td>1 day</td>
                <td>1,500,000</td>
                <td rowspan="2">※ 인원추가 + 100,000원/인</td>
              </tr>
              <tr>
                <td>제철낚시</td>
                <td>1 day</td>
                <td>1,400,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="rowSpace"><hr /></div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">추가옵션</div>

          <GallerySlideCarousels :slides="addition_slides" :slide-count="3" :thumb-slide-width="50" />

          <table class="col-lg-12 table table-bordered subTable">
            <thead>
              <tr>
                <td>구분</td>
                <td colspan="2">옵션(2인기준)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>150,000</td>
                <td>수테라피, 사격, 오모로비짜 스파(40분)</td>
                <td rowspan="4">※ 3인 이상 시 전화 문의</td>
              </tr>
              <tr>
                <td>200,000</td>
                <td>메디컬 스파(바디)</td>
              </tr>
              <tr>
                <td>350,000</td>
                <td>오모로비짜 스파(90분)</td>
              </tr>
              <tr>
                <td>플라워 세팅</td>
                <td>300,000 / 500,000 / 700,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="rowSpace"><hr /></div>
        <div class="col-lg-12 serviceArea">
          <div class="subTitle">기타사항</div>
          <div class="subDetail">
            <div>※ 5세 이상부터 1인 요금이 적용됩니다.</div>
            <div>※ 경우에 따라 미성년자의 경우에는 보호자 동반이 필요할 수 있습니다.</div>
            <div>※ 단순 고객변심에 의한 취소/환불은 업체 위약 규정에 따라 적용됩니다.</div>
            <div>
              - 7일 전 위약금 0%, 6일 전 ~ 5일 전 30%, 4일 전 50%, 3일 전~2일 전(~17시) 70%, 2일전(17시~) ~ 당일 100%
              위약금 부과
            </div>
            <div>※ 천재지변 및 업체의 운영 상 부득이한 상황으로 취소 시, 위약 규정이 적용되지 않습니다.</div>
            <div>※ 여행자보험 포함 상품입니다.</div>
            <div>※ 요금표 외 기타 인원은 전화 문의</div>
          </div>
        </div>
        <div class="rowSpace"></div>
      </div>
    </div>
  </div>
</template>
<script>
import GallerySlideCarousels from '@/components/GallerySlideCarousels';

export default {
  name: 'jejufisherman',
  data() {
    return {
      main_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage04.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage04-2.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage04-3.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage04-4.jpg')},
      ],
      addition_slides: [
        {image: require('@/assets/images/pleisure/pleisure_bodyimage07.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage08.jpg')},
        {image: require('@/assets/images/pleisure/pleisure_bodyimage09.jpg')},
      ],
    };
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '제주어부'});
  },
  components: {GallerySlideCarousels},
};
</script>

<style scoped lang="scss">
td {
  vertical-align: middle;
}
.top {
  padding-top: 11rem;
}
.mainTitle {
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: -0.1rem;
}
.mainTitleLine {
  width: 10%;
  > hr {
  }
}
.mainIntro {
}
.buttonArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resserve-button {
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: #524a3d;
    color: #fff;
  }
  .prev-button {
    justify-content: center;
    width: 8rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    background-color: white;
    color: #000;
    border: 1px solid black;
  }
}
.subContainer {
  width: 100%;
}
.mainInfo {
  padding: 1rem;
  width: 100%;
  height: 100%;
  > div {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
.serviceArea {
  padding: 1rem;
  > span {
    font-size: 0.8rem;
  }
}
.subTitle {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.subDetail {
  font-size: 0.9rem;
  padding-left: 1rem;
}
.subTable {
  > thead {
    background-color: #eaeaea;

    > tr > td {
      text-align: center;
      font-weight: 600;
    }
  }
  > tbody {
    > tr > td {
      text-align: center;
      font-size: 0.9rem;
    }
  }
}

.rowSpace {
  width: 100%;
  height: 2rem;
  > hr {
  }
}
.headerimage {
  width: 100%;
  margin: 0;
  content: url('~@/assets/images/pleisure/pleisure_headerimage.jpg');
}

@media (max-width: 992px) {
  .top {
    padding-top: 5rem;
  }

  .headerimage {
    display: none;
  }
}
</style>
